<template>
  <div>
    <div class="header_min top0">
      <div class="wrap header_min_box">
        <div class="fl logo" @click="entryIndex">
          <img src="@/assets/image/funuo.png" alt />
          孚诺大药房
        </div>
        <span class="nameType">{{ nameType }}</span>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["nameType"],
  methods: {
    // 进入首页
    entryIndex() {
      this.$emit("changeState", 0);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/header";
.nameType {
  display: inline-block;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 40px;
}
.top0 {
  top: 0 !important;
}
.header_min {
  height: 108px;
  border-bottom: 1px solid #38eec1;
  margin-top: 66px;
}
</style>