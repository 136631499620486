<template>
  <section class="signature">
    <div class="signatureBox">
      <div class="canvasBox" ref="canvasHW">
        <canvas ref="canvasF" @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"></canvas>
        <div class="btnBox">
          <!-- <div @click="overwrite">重写</div> -->
          <button @click="commit">保存</button>
          <button @click="overwrite">重写</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      stageInfo: "",
      imgUrl: "",
      client: {},
      points: [],
      canvasTxt: null,
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      endY: 0,
      endX: 0,
      w: null,
      h: null,
      isDown: false,
      isViewAutograph: this.$route.query.isViews > 0,
      contractSuccess: this.$route.query.contractSuccess
    };
  },
  mounted() {
    let canvas = this.$refs.canvasF;
    canvas.height = 230;
    canvas.width = 530;
    this.canvasTxt = canvas.getContext("2d");
    this.stageInfo = canvas.getBoundingClientRect();
  },
  methods: {
    //pc
    mouseDown(ev) {
      ev = ev || event;
      ev.preventDefault();
      let obj = {
        x: ev.offsetX,
        y: ev.offsetY
      };
      this.startX = obj.x;
      this.startY = obj.y;
      this.canvasTxt.beginPath();
      this.canvasTxt.lineWidth = 5;
      this.canvasTxt.moveTo(this.startX, this.startY);
      this.canvasTxt.lineTo(obj.x, obj.y);
      this.canvasTxt.stroke();
      this.canvasTxt.closePath();
      this.points.push(obj);
      this.isDown = true;
    },
    mouseMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (this.isDown) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },
    mouseUp(ev) {
      ev = ev || event;
      ev.preventDefault();
      let obj = {
        x: ev.offsetX,
        y: ev.offsetY
      };
      this.canvasTxt.beginPath();
      this.canvasTxt.moveTo(this.startX, this.startY);
      this.canvasTxt.lineTo(obj.x, obj.y);
      this.canvasTxt.stroke();
      this.canvasTxt.closePath();
      this.points.push(obj);
      this.points.push({ x: -1, y: -1 });
      this.isDown = false;
    },
    //重写
    overwrite() {
      this.canvasTxt.clearRect(
        0,
        0,
        this.$refs.canvasF.width,
        this.$refs.canvasF.height
      );
      this.points = [];
    },
    //提交签名
    commit() {
      this.imgUrl = this.$refs.canvasF.toDataURL();
      this.$emit("getImgUrl", this.imgUrl)
      this.overwrite();
      // console.log(this.$refs.canvasF.toDataURL()); //签名img回传后台
    }
  }
};
</script>

<style scoped>
.signature {
  width: calc(100% - 2px);
  margin: 0 auto;
  margin-top: 1px;
}
.signatureBox {
  height: 230px;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
.canvasBox {
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  height: 230px;
}
canvas {
  height: 230px;
}
.btnBox button{
  position: absolute;
  bottom: 25px;
  padding: 5px 25px;
  background: #fff;
  color: red;
  border: 1px solid red;
  border-radius: 12px;
  outline: 0;
  left: 260px;
  cursor: pointer;
  text-align: center;
}
.btnBox button:nth-of-type(1) {
  left: 190px;
}
.btnBox button:nth-of-type(2) {
  left: 310px;
}
</style>