<template>
	<div>
		<div class="header-top bg1">
			<div class="wrap header-top-box">
				<div @mouseover="app_show = 1" @mouseout="app_show = 0" :class="{ app_top_active: app_show == 1 }" class="fl">
					<img src="@/assets/image/top_APP_icon@2x.png" alt />
					手机APP
					<div v-show="app_show == 1" class="header-app-img">
						<div class="fl">
							<div id="qrcode1" ref="qrcode1"></div>Android版
							<i></i>
							<i></i>
						</div>
						<div class="fl">
							<div id="qrcode2" ref="qrcode2"></div>IOS版
							IOS版
						</div>
					</div>
				</div>
<!-- 				<div :class="{ app_top_active: pharmacy_Addres_show == 1 }" style="width:220px" class="fl" v-if="shop.shop_name">
					<img src="@/assets/image/top_position_icon@2x.png" alt />
					{{shop.shop_name}}
					<div v-show="pharmacy_Addres_show == 1" class="header-Pharmacy-addres">
						<div class="h-p-a-top">
							<div @click="pharmacy_Addres_search = 0" :class="{ pharmacy_Addres_active: pharmacy_Addres_search == 0 }">选择药店</div>
							<div @click="pharmacy_Addres_search = 1" :class="{ pharmacy_Addres_active: pharmacy_Addres_search == 1 }">搜索药店</div>
							<i></i>
							<img @click="pharmacy_Addres_show = 0" src="@/assets/image/Persondetails_Popup_Close_icon@2x.png" alt />
						</div>
					</div>
				</div> -->
				<div @mouseover="userList = 1" @mouseout="userList = 0" class="fr user">
					<div class="news" @click="lookMessage">消息</div>
					<div class="news" @mouseenter="show_out = 1" @mouseleave="show_out = 0" @click.prevent="entryPersonal">
						<span>{{ user.user_login }}</span>
						<i class="el-icon-arrow-down"></i>
						<div class="out" v-if="show_out == 1">
							<i></i>
							<div class="server">
								<div @click.stop="changeLandPage($event, i)" @mouseover="userArrActive = i" @mouseout="userArrActive = -1"
								 :class="{userArrActive : userArrActive ==i}" v-for="(v,i) in myArr" :key="i" class="list" v-if="v.txt!=''">
										<img v-show="userArrActive ==i" :src="v.img1" alt />
										<img v-show="userArrActive !=i" :src="v.img2" alt />
										{{v.txt}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title :visible.sync="dialogVisible" width="30%">
			<span>确定要退出？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="removeToekn()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import AMap from "AMap"; // 引入高德地图
	import {
		get_shop_list
	} from "@/request/public";
	import {
		usergetinfo
	} from "@/request/user";
	import {
		setDuty
	} from "@/request/personnel";
	import {
		getDutyStatus
	} from "@/request/personnel";
	import Vue from "vue";
	import QRCode from "qrcodejs2";

	export default {
		data() {
			return {
				app_show: 0, //手机app 二维码下载 显示
				app_android_url: "", //安卓下载地址
				app_ios_url: "", //iOS下载地址
				pharmacy_Addres_show: 0, //药店选择 显示隐藏
				pharmacy_Addres_search: 0, // p判断 是不是 在 搜索页面
				lat: -1, //经纬
				lng: -1, //经纬
				loactionList: [], //药店选择
				Pharmacy: [], //药店搜索列表
				userList: 1, // 个人列表
				userListActive: -1, // 列表选择
				user: [],
				shop: {},
				show_out: false,
				userArrActive: -1,
				dialogVisible: false,
				myArr: [{
						txt: "",
						img1: require("@/assets/image/top_Open_Order_hover@2x.png"),
						img2: require("@/assets/image/top_Open_Order@2x.png")
					},
					{
						txt: "修改资料",
						img1: require("@/assets/image/top_Open_Setup_hover@2x.png"),
						img2: require("@/assets/image/top_Open_Setup@2x.png")
					},
					{
						txt: "个人中心",
						img1: require("@/assets/image/top_Open_Doctor_hover@2x.png"),
						img2: require("@/assets/image/top_Open_Doctor@2x.png")
					},
					{
						txt: "退出",
						img1: require("@/assets/image/top_Open_Signout_hover@2x.png"),
						img2: require("@/assets/image/top_Open_Signout@2x.png")
					}
				]
			};
		},
		created() {
			Vue.prototype.$token = localStorage.getItem("lzyyToken");
			if (this.$token != undefined) {
				this.start();
			}
			let temp = this.myArr[0];
			getDutyStatus({
				token: this.$token
			}).then(res => {
				// if(res1.data.is_duty==1){
				// 	console.log("上班状态");
				// }else{
				// 	console.log("下班状态");
				// }
				if (res.code == 1) {
					if (res.data.is_duty == 0) {
					if (this.$route.fullPath == "/home/doctor/index") {
							temp.txt = "医生上线";
						} else if (this.$route.fullPath === "/home/expert/index") {
							temp.txt = "专家上线";
						} else if (this.$route.fullPath === "/home/deployment/index") {
							temp.txt = "调配员上线";
						} else if (this.$route.fullPath === "/home/check/index") {
							temp.txt = "复合员上线";
						}
					} else {
					 if (this.$route.fullPath == "/home/doctor/index") {
							temp.txt = "医生下线";
						} else if (this.$route.fullPath === "/home/expert/index") {
							temp.txt = "专家下线";
						} else if (this.$route.fullPath === "/home/deployment/index") {
							temp.txt = "调配员下线";
						} else if (this.$route.fullPath === "/home/check/index") {
							temp.txt = "复合员下线";
						}
					}
				}
			});
		},
		mounted() {
			this.getLocation();
			this.qrImg();
		},
		methods: {
			entryPersonal() {
				// 进入个人中心
				// 进入药师端个人中心
				if (this.$route.fullPath === "/home/pharmacist/index") {
					this.$router.push("/personal/pharmacist/index");
				} else if (this.$route.fullPath === "/home/doctor/index") {
					this.$router.push("/personal/doctor/index");
				} else if (this.$route.fullPath === "/home/expert/index") {
					this.$router.push("/personal/expert/index");
				} else if (this.$route.fullPath === "/home/deployment/index") {
					this.$router.push("/personal/deployment/index");
				} else if (this.$route.fullPath === "/home/check/index") {
					this.$router.push("/personal/check/index");
				}
			},
			lookMessage() {
				// 跳转到个人中心消息页面
				if (this.$route.fullPath === "/home/pharmacist/index") {
					this.$router.push("/personal/pharmacist/index?msg=1");
				} else if (this.$route.fullPath === "/home/doctor/index") {
					this.$router.push("/personal/doctor/index?msg=1");
				} else if (this.$route.fullPath === "/home/expert/index") {
					this.$router.push("/personal/expert/index?msg=1");
				} else if (this.$route.fullPath === "/home/deployment/index") {
					this.$router.push("/personal/deployment/index?msg=1");
				} else if (this.$route.fullPath === "/home/check/index") {
					this.$router.push("/personal/check/index?msg=1");
				}
			},
			Gosearch() {},
			send() {},
			start() {
				usergetinfo({
					token: this.$token
				}).then(res => {
					this.user = res.data;
				});
			},
			changeLandPage(e, i) {
				if (i == 0) {
					getDutyStatus({
						token: this.$token
					}).then(res1 => {
						
						if (res1.code == 1) {
							setDuty({
								token: this.$token,
								is_duty: res1.data.is_duty == 0 ? 1 : 0
							}).then(res => {
								console.log("shangxian");
								if (res.code == 1) {
									this.$message({
										type: "success",
										message: res1.data.is_duty == 0 ? "你已上线" : "你已下线"
									});
									if (res1.data.is_duty == 0) {
										if (this.$route.fullPath == "/home/doctor/index") {
											this.myArr[0].txt = "医生下线";
										} else if (this.$route.fullPath === "/home/expert/index") {
											this.myArr[0].txt = "专家下线";
										} else if (this.$route.fullPath === "/home/deployment/index") {
											this.myArr[0].txt = "调配员下线";
										} else if (this.$route.fullPath === "/home/check/index") {
											this.myArr[0].txt = "复合员下线";
										}
									} else {
										
										if (this.$route.fullPath == "/home/doctor/index") {
											this.myArr[0].txt = "医生上线";
										} else if (this.$route.fullPath === "/home/expert/index") {
											this.myArr[0].txt = "专家上线";
										} else if (this.$route.fullPath === "/home/deployment/index") {
											this.myArr[0].txt = "调配员上线";
										} else if (this.$route.fullPath === "/home/check/index") {
											this.myArr[0].txt = "复合员上线";
										}
									}
								}
							});
						}
					});
				} else if (i == 1) {
					console.log(this.$route.fullPath);
					if (this.$route.fullPath === "/home/pharmacist/index") {
						this.$router.push("/personal/pharmacist/index?state=1");
					} else if (this.$route.fullPath === "/home/doctor/index") {
						this.$router.push("/personal/doctor/index?state=1");
					} else if (this.$route.fullPath === "/home/expert/index") {
						this.$router.push("/personal/expert/index?state=1");
					} else if (this.$route.fullPath === "/home/deployment/index") {
						this.$router.push("/personal/deployment/index?state=1");
					} else if (this.$route.fullPath === "/home/check/index") {
						this.$router.push("/personal/check/index?state=1");
					}
				} else if (i == 2) {
					if (this.$route.fullPath == "/home/pharmacist/index") {
						this.$router.push("/personal/pharmacist/index")
					} else if (this.$route.fullPath == "/home/doctor/index") {
						this.$router.push("/personal/doctor/index");
					} else if (this.$route.fullPath === "/home/expert/index") {
						this.$router.push("/personal/expert/index");
					} else if (this.$route.fullPath === "/home/deployment/index") {
						this.$router.push("/personal/deployment/index");
					} else if (this.$route.fullPath === "/home/check/index") {
						this.$router.push("/personal/check/index");
					}

				} else if (i === 3) {
					this.removeToekn();
				}
			},
			qrImg() {
				this.$axios.post("/api/public/getSysconf").then(res=>{
					if(res.data.code==1){
						this.app_android_url=res.data.data.app_android_url;
						this.app_ios_url=res.data.data.app_ios_url;
					}
				})
				let qrcode1 = new QRCode("qrcode1", {
					width: 302, // 二维码宽度
					height: 302, // 二维码高度
					text: this.app_android_url
				});
				let qrcode2 = new QRCode("qrcode2", {
					width: 302, // 二维码宽度
					height: 302, // 二维码高度
					text: this.app_ios_url
				});
			},
			location() {
				get_shop_list({
					lng: this.lng,
					lat: this.lat
				}).then(res => {
					if (res.data.data != "") {
						this.loactionList = res.data.data;
						this.shop = this.loactionList[0];
						let id = this.loactionList[0].id;
						localStorage.setItem("lzyyShopId", id);
						localStorage.setItem("lzyyShopLng", this.lng);
						localStorage.setItem("lzyyShopLat", this.lat);
					} else {
						localStorage.removeItem("lzyyShopId");
					}
				});
			},
			removeToekn() {
				this.dialogVisible = false;
				localStorage.removeItem("lzyyToken");
				this.$user.state = 0;
				this.$user.token = "";
				this.$router.replace({
					path: "/land"
				});
				this.closeSocket();
			},
			closeSocket() {
				let obj = {
					method: "logout"
				};
				this.$store.state.sendMessage(JSON.stringify(obj));
				this.$store.state.isLogin = false;
			},
			getLocation() {
				let Athis = this;
				var map = new AMap.Map("container", {
					resizeEnable: true
				});
				AMap.plugin("AMap.Geolocation", function() {
					var geolocation = new AMap.Geolocation({
						enableHighAccuracy: true, //是否使用高精度定位，默认:true
						timeout: 10000 //超过10秒后停止定位，默认：5s
					});
					map.addControl(geolocation);
					geolocation.getCurrentPosition(function(status, result) {
						if (status == "complete") {
							onComplete(result);
						} else {
							onError(result);
						}
					});
				});
				//解析定位结果
				function onComplete(data) {
					Athis.lat = data.position.lat;
					Athis.lng = data.position.lng;
					Athis.location();
				}
				//解析定位错误信息
				function onError(data) {
					Athis.lat = 35.55122265;
					Athis.lng = 116.78393841;
					Athis.location();
				}
			}
		}
	};
</script>
<style lang="less" scoped>
	@import "../../assets/less/header";

	.header-top {
		height: 66px;
		background: #f5f5f5;

		.wrap {
			margin-top: 15px;
		}
	}

	.wrap.header-top-box {
		margin-top: 0px;
	}

	.fr,
	.fl {
		margin-top: 0px;
		font-size: 18px;
	}

	.news {
		font-size: 18px;
		margin-left: 10px;
		cursor: pointer;
	}

	.server {
		position: absolute;
		top: 66px;
		width: 176px;
		left: -20px;
		z-index: 2;
		padding-bottom: 20px;
		border-radius: 6px;
		background: white;
		box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
	}

	.server>.list:last-child {
		img {
			margin-left: -30px;
		}
	}

	.user {
		
	}

	.ffrs {
		position: relative;
	}
	.fr .out>i {
		position: absolute;
		display: block;
		bottom: -10px;
		left: 60px;
		width: 20px;
		height: 20px;
		background: white;
		transform: rotate(45deg);
		z-index: 1;
		box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
	}

	.server>.list {
		color: #000;
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		margin-top: 10px;
		text-align: center;

		img {
			width: 32px;
			height: 32px;
			vertical-align: middle;
		}
	}

	.userArrActive {
		color: #4eeec8 !important;
	}

	.userArrActives {
		color: #4eeec8;
	}
</style>
